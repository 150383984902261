import React from "react"
import Moment from "react-moment"
import styled from "@emotion/styled"
import colors from "styles/colors"
import PropTypes from "prop-types"
import dimensions from "styles/dimensions"
import { RichText } from "prismic-reactjs"
import { ProjectCardTools, ProjectCardTool } from "./ProjectCard"

const ExperienceItemContainer = styled("div")`
  padding: 1em 2.5em 3em 2.5em;
  text-decoration: none;
  color: currentColor;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  transition: all 150ms ease-in-out;

  a {
    /* text-decoration: none; */
    transition: all 100ms ease-in-out;

    &:nth-of-type(1) {
      color: ${colors.orange500};
    }

    &:hover {
      cursor: pointer;
      transition: all 100ms ease-in-out;

      &:nth-of-type(1) {
        color: ${colors.orange500};
        background-color: ${colors.orange200};
      }
    }
  }

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    padding: 1em 0em 4em 0em;
    flex-wrap: wrap;
    /* flex-direction: row-reverse; */
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    padding: 1em 0em 4 0em;
    flex-wrap: wrap;
    /* flex-direction: column-reverse; */
  }

  &:hover {
    /* cursor: pointer; */
    transition: all 100ms ease-in-out;

    .ExperienceItemAction {
      color: ${colors.blue500};
      transition: all 150ms ease-in-out;

      span {
        transform: translateX(0px);
        opacity: 1;
        transition: transform 150ms ease-in-out;
      }
    }
  }
`

const ExperienceJobContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-right: 2em;
`

const ExperienceTitleContainer = styled("div")`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 2em;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    display: block;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    display: block;
  }
`

const ExperienceItemTitle = styled("h3")`
  margin: 0;
  margin-right: 0.5em;
  margin-bottom: 0.4em;
  color: ${props => props.titleColor};
  opacity: 0.8;
`

const ExperienceItemCompany = styled("div")`
  font-size: 1.2em;
  margin: 0;
  margin-right: 0.5em;
  color: ${colors.grey700};
  opacity: 0.8;
  span {
    margin-left: 1em;
    transform: translateX(-8px);
    display: inline-block;
    transition: transform 400ms ease-in-out;
    opacity: 0;
    transition: all 150ms ease-in-out;
  }

  &:hover {
    color: ${props => props.titleColor};
    transition: all 150ms ease-in-out;
    /* cursor: pointer; */
    opacity: 0.8;

    span {
      transform: translateX(-6px);
      opacity: 1;
      transition: transform 150ms ease-in-out;
    }
  }
`

const ExperienceItemDate = styled("div")`
  margin: 0;
  color: ${colors.grey500};
  min-width: 200px;
  text-align: right;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    text-align: left;
    padding-bottom: 1em;
    width: 100%;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    text-align: left;
    padding-bottom: 1em;
    width: 100%;
  }
`

const ExperienceItemDescription = styled("div")`
  margin-top: 2em;
  margin-bottom: 2em;

  p:last-of-type {
    margin: 0;
  }

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    margin-top: 0em;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    margin-top: 0em;
  }
`

const ExperienceItem = ({
  jobTitle,
  company,
  fromDate,
  toDate,
  description,
  location,
  flag,
  uid,
  tools,
  titleColor = "black",
}) => (
  <ExperienceItemContainer className="ExperienceItem" to={`/blog/${uid}`}>
    <ExperienceTitleContainer>
      <div>
        <ExperienceItemTitle
          titleColor={titleColor}
        >{`${jobTitle[0].text}`}</ExperienceItemTitle>
        <ExperienceItemCompany titleColor={titleColor}>
          {`${company[0].text}, ${location[0].text}`}
          <span> {`${flag[0].text}`}</span>
        </ExperienceItemCompany>
      </div>
      <ExperienceItemDate>
        <Moment format="MMM YYYY">{fromDate}</Moment> {`- `}
        {toDate && <Moment format="MMM YYYY">{toDate}</Moment>}
        {!toDate && "present"}
      </ExperienceItemDate>
    </ExperienceTitleContainer>
    <ExperienceJobContainer>
      <ExperienceItemDescription>
        {RichText.render(description)}
      </ExperienceItemDescription>
      <ProjectCardTools>
        {tools.map(tool => (
          <ProjectCardTool> {tool}</ProjectCardTool>
        ))}
      </ProjectCardTools>
    </ExperienceJobContainer>
  </ExperienceItemContainer>
)

export default ExperienceItem

ExperienceItem.propTypes = {
  jobTitle: PropTypes.array.isRequired,
  company: PropTypes.array.isRequired,
  location: PropTypes.array.isRequired,
  flag: PropTypes.array.isRequired,
  fromDate: PropTypes.string.isRequired,
  toDate: PropTypes.string,
  tools: PropTypes.array.isRequired,
  description: PropTypes.array.isRequired,
  titleColor: PropTypes.string,
}
