import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import dimensions from "styles/dimensions"
import Layout from "components/Layout"
import { Section as Sec } from "."
import colors from "styles/colors"
import ExperienceItem from "../components/ExperienceItem"

const experienceColor = colors.blue500
const educationColor = colors.orange500
const miscColor = colors.purple500

const PageTitle = styled("h1")`
  margin-bottom: 1em;
`

const PortfolioTitle = styled("h2")`
  margin: 0.2em 0em;
  color: ${colors.orange500};
`

const Section = styled(Sec)`
  margin-bottom: 2em;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    margin-bottom: 0em;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    margin-bottom: 0em;
  }
`

const PortfolioSectionTitle = styled("h2")`
  margin-bottom: 1em;
  color: ${props => props.color};
`

const PortfolioSubtitle = styled("h3")`
  margin: 0.5em 0em;
  margin-bottom: 1em;
  font-weight: 300;
  font-family: sans-serif;
  color: ${colors.grey500};
`

// const PortfolioGrid = styled("div")`
//   @media (max-width: 1050px) {
//   }

//   @media (max-width: ${dimensions.maxwidthMobile}px) {
//   }
// `

const Portfolio = ({ portfolio, experience, education, misc, meta }) => (
  <>
    <Helmet
      title={`🧙‍♂️ portfolio | dhruv c`}
      titleTemplate={`%s | portfolio | dhruv c`}
      meta={[
        {
          name: `description`,
          content: meta.description,
        },
        {
          property: `og:title`,
          content: `portfolio | dhruv c`,
        },
        {
          property: `og:description`,
          content: meta.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: meta.author,
        },
        {
          name: `twitter:title`,
          content: meta.title,
        },
        {
          name: `twitter:description`,
          content: meta.description,
        },
      ].concat(meta)}
    />
    <Layout>
      <PageTitle>Portfolio</PageTitle>
      <Section>
        <>
          <PortfolioTitle>{portfolio.portfolio_title[0].text}</PortfolioTitle>
          <PortfolioSubtitle>
            {`${portfolio.portfolio_subtitle[0].text} / ${portfolio.portfolio_subtitle_person_types[Math.floor(Math.random(portfolio.portfolio_subtitle_person_types.length))].person_types[Math.floor(Math.random(portfolio.portfolio_subtitle_person_types.length))].text}`}
          </PortfolioSubtitle>
        </>
      </Section>
      <Section>
        <PortfolioSectionTitle color={experienceColor}>
          {portfolio.portfolio_experience_title[0].text}
        </PortfolioSectionTitle>
        {experience.map((exp, i) => (
          <ExperienceItem
            titleColor={experienceColor}
            jobTitle={exp.node.experience_job_title}
            company={exp.node.experience_company}
            fromDate={exp.node.experience_from_date}
            toDate={exp.node.experience_to_date}
            description={exp.node.experience_job_description}
            location={exp.node.experience_location}
            tools={exp.node.experience_tools.map(
              tool => tool.experience_tool[0].text
            )}
            flag={exp.node.experience_flag}
          />
        ))}
      </Section>
      <Section>
        <PortfolioSectionTitle color={educationColor}>
          {portfolio.portfolio_education_title[0].text}
        </PortfolioSectionTitle>
        {education.map(edu => {
          return (
            <ExperienceItem
              titleColor={educationColor}
              jobTitle={edu.node.education_degree}
              company={edu.node.education_university}
              fromDate={edu.node.education_from_date}
              toDate={edu.node.education_to_date}
              description={edu.node.education_description}
              location={edu.node.education_location}
              tools={edu.node.education_tools.map(
                tool => tool.education_tool[0].text
              )}
              flag={edu.node.education_flag}
            />
          )
        })}
      </Section>
      <Section>
        <PortfolioSectionTitle color={miscColor}>
          {portfolio.portfolio_misc_title[0].text}
        </PortfolioSectionTitle>
        {misc.map(mis => {
          return (
            <ExperienceItem
              titleColor={miscColor}
              jobTitle={mis.node.misc_title}
              company={mis.node.misc_subtitle}
              fromDate={mis.node.misc_from_date}
              toDate={mis.node.misc_to_date}
              description={mis.node.misc_description}
              location={mis.node.misc_location}
              tools={mis.node.misc_tools.map(tool => tool.misc_tool[0].text)}
              flag={mis.node.misc_flag}
            />
          )
        })}
      </Section>
    </Layout>
  </>
)

export default ({ data }) => {
  const experience = data.prismic.allExperiences.edges
  const education = data.prismic.allEducations.edges
  const misc = data.prismic.allMiscs.edges
  const portfolioPage = data.prismic.allPortfolios.edges.slice(0, 1).pop()
  const meta = data.site.siteMetadata
  if (!portfolioPage || !experience) return null

  return (
    <Portfolio
      portfolio={portfolioPage.node}
      experience={experience}
      education={education}
      misc={misc}
      meta={meta}
    />
  )
}

Portfolio.propTypes = {
  experience: PropTypes.array.isRequired,
  education: PropTypes.array.isRequired,
  misc: PropTypes.array,
  portfolio: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}

export const query = graphql`
  {
    prismic {
      allExperiences(sortBy: experience_from_date_DESC) {
        edges {
          node {
            experience_job_title
            experience_from_date
            experience_to_date
            experience_company
            experience_job_description
            experience_location
            experience_tools {
              experience_tool
            }
            experience_flag
            _meta {
              uid
            }
          }
        }
      }
      allEducations {
        edges {
          node {
            education_degree
            education_university
            education_to_date
            education_from_date
            education_description
            education_location
            education_tools {
              education_tool
            }
            education_flag
            _meta {
              uid
            }
          }
        }
      }
      allMiscs {
        edges {
          node {
            misc_title
            misc_subtitle
            misc_to_date
            misc_from_date
            misc_description
            misc_location
            misc_tools {
              misc_tool
            }
            misc_flag
            _meta {
              uid
            }
          }
        }
      }
      allPortfolios {
        edges {
          node {
            portfolio_title
            portfolio_subtitle
            portfolio_subtitle_person_types {
              person_types
            }
            portfolio_experience_title
            portfolio_education_title
            portfolio_misc_title
            _meta {
              uid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
